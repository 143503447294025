import React from 'react';
import footerImage from '../assets/images/footer-image.png';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom'; 

// import IGLogo from '../assets/images/IGLogo.png'
// import MailLogo from '../assets/images/MailLogo.png'
// import LinkedinLogo from '../assets/images/LinkedinLogo.png'
const Footer = () => {
  return (

    <footer>
            <img src={footerImage} alt="Footer" className="footer-image" />
            <p>
                  <Link target="_blank" rel="noopener noreferrer" to="/terms&conditions">Termini e Condizioni</Link> | 
                  <Link target="_blank" rel="noopener noreferrer" to="/privacy">Privacy Policy</Link>
            </p>

          
        
            {/* <div className="footer-links-block">
              <a href="/terms&conditions" target="_blank" rel="noopener noreferrer">Terms & Conditions</a>
              <a href="/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
              <a href="/contact">Contatti</a>
            </div>
            <hr className="footer-separator" />

            <div className="footer-info">
              <div className="footer-left">
                <p>Trovaci anche su:</p>
                <div className="social-icons">
                  <a href="https://www.instagram.com/atmooss/" target="_blank" rel="noopener noreferrer">
                    <img src={IGLogo} alt="Instagram" />
                  </a>
                  <a href="mailto:info@atmooss.com">
                    <img src={MailLogo} alt="Mail" />
                  </a>
                  <a href="https://www.linkedin.com/company/atmooss/" target="_blank" rel="noopener noreferrer">
                    <img src={LinkedinLogo} alt="LinkedIn" />
                  </a>
                </div>
              </div>
              <div className="footer-right">
                <p>ATMOOSS</p>
                <p className = "nota-diritti">Tutti i diritti riservati.</p>
              </div>
            </div> */}
          </footer>


  );
}

export default Footer;