import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Daily from './pages/Daily';
import DailyAuth from './pages/DailyAuth';
import './App.css';  // Importiamo lo stile
import TermsAndConditions from './pages/TermsAndConditions';
import Privacy from './pages/Privacy';

function App() {
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };


  return (
    <Router>
      <div className="app-container">
        {/* Main content */}
        <div className="content">
          <Routes>
            <Route path="/" element={<Daily />} />
            <Route path="/daily" element={<Daily />} />
            {/* <Route path="/experiences" element={<Experiences />} /> */}
            {/* <Route path="/about" element={<About />} /> */}
            <Route path="/terms&conditions" element={<TermsAndConditions />} />
            <Route path="/privacy" element={<Privacy />} />
            {/* <Route path="/contact" element={<Contact />} /> */}
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
