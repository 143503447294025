import React from 'react';
import '../css/TermsAndConditions.css'
const TermsAndConditions = () => {
  return (
    <div className="terms-container">
      <h1>Termini e Condizioni</h1>

      <section>
        <h2>Servizi offerti e sintesi delle condizioni</h2>
        <p>
          ATMOOSS si impegna ed occupa di creare contatti e occasioni di incontro fra le persone, in ambienti pubblici e accuratamente selezionati da ATMOOSS, oltre agli ulteriori servizi a ciò collegati (“Servizi”).
        </p>
        <p>
          In tali occasioni sarà una libera scelta degli utenti di fornire eventuali proprie informazioni personali agli altri partecipanti e non potrà mai essere considerata responsabilità di ATMOOSS di verificare se tali informazioni siano corrette così come di assistere gli utenti negli eventuali contatti successivi.
        </p>
        <p>
          Resta inteso che accettando di utilizzare i servizi di ATMOOSS, l’utente si assume ogni responsabilità per quanto possa derivare dalle interazioni con gli altri partecipanti, sollevando ATMOOSS da ogni responsabilità in tal senso.
        </p>
      </section>

      <section>
        <h2>INTRODUZIONE</h2>
        <p>
          Accedendo ai Servizi di ATMOOSS o utilizzandoli, accetti di vincolarti al presente Accordo sulle Condizioni Generali di Contratto (“Termini” o “Accordo”), che comprende anche la nostra informativa sulla privacy.
        </p>
        <p>
          Ai fini delle presenti condizioni generali, si intende per:
        </p>
        <ul>
          <li><strong>UTENTE:</strong> chiunque faccia uso dei servizi o partecipi ad eventuali incontri o eventi organizzati da ATMOOSS.</li>
          <li><strong>SERVIZI:</strong> i servizi forniti da ATMOOSS, inclusi gli eventi o incontri da questa organizzati.</li>
        </ul>
      </section>

        <strong>L’UTENTE si impegna in particolare a NON:</strong>
        <ul>
          <li>Fornire informazioni false sulla propria identità, età o affiliazione con persone o entità;</li>
          <li>Utilizzare i Servizi in modo da danneggiarli o impedirne l'utilizzo ad altre persone;</li>
          <li>Interferire, interrompere o incidere negativamente sui partecipanti ai servizi;</li>
          <li>Utilizzare i Servizi per scopi dannosi, illegali o efferati;</li>
          <li>Molestare, bullizzare, intimidire, aggredire o diffamare altri utenti;</li>
          <li>Pubblicare o condividere Contenuti vietati;</li>
          <li>Richiedere password o informazioni personali per scopi commerciali o illegali;</li>
          <li>Richiedere ad altri utenti denaro o altri beni di valore.</li>
        </ul>

        <p>L’Utente è consapevole e riconosce:</p>
        <ul>
          <li>Di essere responsabile pienamente di ogni proprio comportamento in occasione degli incontri ed eventi organizzati da ATMOOSS;</li>
          <li>Di partecipare agli incontri in stato di perfetta lucidità;</li>
          <li>Di garantire di avere i requisiti necessari per la conduzione di veicoli personali.</li>
        </ul>

      <section>
        <h2>CONTENUTI</h2>
        <p>
        L’Utente autorizza espressamente ATMOOSS ad effettuare riprese audio, foto e video (in seguito anche solo “Contenuti”) durante gli eventi e incontri dalla stessa organizzati. L’utente è consapevole che tali contenuti potrebbero contenere anche l’immagine audio/video dell’utente stesso e, in tal senso, concede fin d’ora (con rinuncia a preventiva visione) ampia liberatoria e licenza, della durata di 99 anni e a titolo gratuito ad ATMOOSS per utilizzare tali contenuti anche contenenti la propria immagine ai soli fini promozionali dell’attività di ATMOOSS (attraverso tutti i mezzi possibili di diffusione, inclusi sociali, spot pubblicitari o altro).
        </p>
        <p>
        L’Utente è altresì consapevole che qualsiasi contenuto che lo stesso fornisca ad ATMOOSS o che ATMOOSS realizzi come sopra resterà di esclusiva proprietà di ATMOOSS.
        </p>
      </section>

      <section>
        <h2>CONTENUTI INAPPROPRIATI E COMPORTAMENTO SCORRETTO; SEGNALAZIONI</h2>
        <p>
        Gli utenti potranno segnalare ad ATMOOSS eventuali comportamenti scorretti riscontrati da parte di altri utenti.
        Resta inteso che l’Utente esonera completamente ATMOOSS da ogni responsabilità in ordine ad eventuali comportamenti scorretti di altri utenti ed è a conoscenza che la segnalazione ad ATMOOSS avrà la sola finalità di consentire ad ATMOOSS, sulla base del suo insindacabile giudizio, di escludere dai Servizi l’eventuale utente segnalato.
        Resta ancora inteso che l’Utente che riscontri comportamenti particolarmente gravi o di rilievo penale ovvero risarcitorio da parte di altri utenti dovrà farne segnalazione e/o denuncia diretta alla Pubblica Autorità, senza che ATMOOSS possa in alcun modo farsi carico di tali accertamenti.
        </p>
      </section>

      <section>
        <h2>DIRITTI e CONTENUTI CONCESSI A ATMOOSS</h2>
        <p>
        Tutti i contenuti che fornisci a ATMOOSS sono di tua proprietà, ma tu ci concedi il diritto di utilizzarli così come previsto dal presente Accordo.
        </p>
        <p>
        Utilizzando i servizi di ATMOOSSS, concedi a ATMOOSS il diritto e una licenza valida in tutto il mondo, perpetua, trasferibile, cedibile in sublicenza ed esente da diritti d'autore per ospitare, archiviare, utilizzare, copiare, mostrare, riprodurre, adattare, modificare, pubblicare, tradurre, ritoccare, riformattare, integrare in altre opere, pubblicizzare, distribuire e rendere altrimenti disponibili al grande pubblico i Tuoi contenuti, comprese le informazioni a cui ci autorizzi ad accedere completamente o in parte, e in ogni modo e in ogni formato o supporto attualmente conosciuti o sviluppati in futuro. 
        </p>
        <p>
        A fronte dell'autorizzazione a utilizzare i nostri Servizi che ATMOOSS ti concede, accetti che noi, le nostre società affiliate e i nostri partner esterni possiamo pubblicare pubblicità sui nostri Servizi. 
        </p>
      </section>

      <section>
        <h2>NESSUN CONTROLLO DEI PRECEDENTI PENALI O VERIFICA DELL'IDENTITÀ</h2>
        <p>
        ATMOOSS non svolge controlli sui precedenti penali o verifiche dell'identità delle persone iscritte. O che comunque utilizzano i propri servizi. ATMOOSS promuove un'esperienza utente all'insegna del rispetto, pertanto non è responsabile del comportamento delle persone coinvolte nei propri servizi. Invitiamo a utilizzare il miglior giudizio possibile durante le interazioni con altre persone.
        </p>
        <p>
        L’Utente (tu) sei la sola persona responsabile delle tue interazioni con altri/e utenti. non possiamo garantire e non garantiamo la tua sicurezza. ti invitiamo a utilizzare sempre il miglior giudizio e ad adottare precauzioni di sicurezza adeguate quando comunichi con altre persone o in caso di incontri nella vita reale. 
        </p>
        <p>
        ATMOOSS promuove un'esperienza utente all'insegna del rispetto, pertanto non è responsabile del comportamento delle persone, all'interno o all'esterno dei Servizi. Accetti di usare cautela in tutte le interazioni con altre persone conosciute tramite ATMOOSS, in particolare se decidi di comunicare con loro all'esterno del Servizio o di incontrarle nella vita reale.
        </p>
      </section>

      <section>
        <h2>LIMITAZIONE DI RESPONSABILITÀ</h2>
        <p>
        La responsabilità di ATMOOSS è limitata alla misura massima consentita dalla legge applicabile.
        </p>
        <p>
        In ogni caso, l’utente è consapevole che per ogni danno o pregiudizio che lo stesso subisca l’eventuale risarcimento dovuto da parte di ATMOOSS non potrà eccedere il limite massimo e cumulativo corrispondente al prezzo del servizio effettivamente corrisposto dall’utente ad ATMOOSS.
        </p>
      </section>

      <section>
        <h2>RISOLUZIONE DELLE CONTROVERSIE</h2>
        <p>
        Nell'improbabile eventualità di una controversia legale, ecco come le Parti accettano di procedere, a eccezione di quanto proibito dalla legge applicabile.
        <br />
        Se per qualsiasi motivo i nostri Servizi non ti hanno soddisfatto, ti preghiamo di contattare prima l'Assistenza clienti di ATMOOSS, in modo da cercare di risolvere i tuoi problemi senza dover ricorrere a un'assistenza esterna. 
        <br />
        Il completamento di questa risoluzione informale delle controversie è una condizione preliminare alla presentazione di un'azione giudiziaria.
        <br />
        Se nel termine di 60 giorni non si sia giunti ad una risoluzione informale della controversia, l’utente potrà adire le vie giudiziarie. In tal caso, la competenza esclusiva per ogni controversia che dovesse nascere dal rapporto e dai servizi offerti da ATMOOSS è quella del Tribunale di Verona. 
        </p>
      </section>

      <section>
        <h2>ACCETTAZIONE DEI TERMINI</h2>
        <p>
          Utilizzando i nostri Servizi, accetti i Termini del presente Accordo.
        </p>
      </section>
    </div>
  );
};

export default TermsAndConditions;
