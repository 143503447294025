import React from 'react';
import '../css/Privacy.css'

const Privacy = () => {
    return (
        <div className="privacy-container">
            <div className="privacy-title"> 
                <h1>INFORMATIVA PRIVACY DATI RACCOLTI</h1>
                <p>Informativa ai sensi e per gli effetti di cui all’art. 13-14, Reg UE 2016/679 <br />
                (Regolamento Europeo in materia di protezione dei dati personali)</p>
            </div>

            {/* Sezione 1 */}
            <section>
                <h2>1. Finalità e base giuridica del trattamento cui sono destinati i dati</h2>
                <p>Il trattamento dei dati personali è finalizzato unicamente ad eseguire gli obblighi contrattuali e ad adempiere a specifiche richieste, nonché ad adempiere agli obblighi normativi, in particolare quelli contabili e fiscali e amministrativi. <br />
                Nelle pagine internet gestite da ATMOOSS qualora siano presenti vari collegamenti ad altri siti internet (link), questi non è responsabile delle pratiche adottate da tali siti/soggetti. Consigliamo quindi di leggere le loro dichiarazioni sulla privacy, che potrebbero essere diverse dalla nostra. 
                <br/> Ai fini dell’indicato trattamento, il titolare potrà venire a conoscenza di dati definiti “sensibili” ai sensi del Reg UE 2016/679. Tali dati saranno utilizzati esclusivamente al fine dell’invio di materiale informativo e/o pubblicitario tramite mail o sms relativi all’attività contrattuale.</p>
            </section>

            {/* Sezione 2 */}
            <section>
                <h2>2. Modalità del trattamento</h2>
                <p>In relazione alle indicate finalità i Suoi dati sono oggetto di trattamento informatico e cartaceo. Le operazioni di trattamento sono attuate in modo da garantire la sicurezza logica, fisica e la riservatezza dei Suoi dati personali. 
                    <br />
                    Per alcune attività (visibilità sui social, riviste specializzate del settore, per esempio) potrebbe ricorrere a strutture e società esterne. A questi soggetti richiediamo massima riservatezza e discrezione nell’uso delle informazioni di cui vengono a conoscenza.</p>
            </section>

            {/* Sezione 3 */}
            <section>
                <h2>3. Natura dei dati personali</h2>
                <p>Costituiscono oggetto di trattamento i Suoi dati personali, la sua mail, eventuale fotografia o video, il numero di telefono fisso o mobile, eventuali altri dati fiscali, contabili giudiziari, curriculum vitae, inerenti lo svolgimento della prestazione fornitale o del servizio da Lei richiesto. 
                    <br /> 
                    L’invio facoltativo, esplicito e volontario di posta elettronica e/o ordinaria agli indirizzi indicati su questo sito comporta la successiva acquisizione dell’indirizzo del mittente, necessario per rispondere alle richieste, nonché degli altri eventuali dati personali inseriti nella missiva.</p>
            </section>

            {/* Sezione 4 */}
            <section>
                <h2>4. Natura obbligatoria o facoltativa del conferimento</h2>
                <p>Il conferimento dei Suoi dati personali non ha natura obbligatoria, ma l’eventuale rifiuto potrebbe rendere impossibile o estremamente difficoltoso l’erogazione dei servizi o la prosecuzione dell’attività per la/il quale ha richiesto il servizio.</p>
            </section>

            {/* Sezione 5 */}
            <section>
                <h2>5. Ambito di comunicazione e diffusione dei dati</h2>
                <p>I Suoi dati potranno essere comunicati a:</p>
                <ul>
                    <li>Tutti i soggetti cui la facoltà di accesso a tali dati è riconosciuta in forza di provvedimenti normativi;</li>
                    <li>Ai nostri collaboratori, dipendenti, nell'ambito delle relative mansioni ove esistenti;</li>
                    <li>A tutte quelle persone fisiche e/o giuridiche, pubbliche e/o private quando la comunicazione risulti necessaria o funzionale allo svolgimento della nostra attività e nei modi e per le finalità sopra illustrate;</li>
                </ul>
            </section>

            {/* Sezione 6 */}
            <section>
                <h2>6. Modalità e durata conservazione dei dati personali</h2>
                <p>I dati che la riguardano verranno conservati per un arco di tempo non superiore a quello necessario al raggiungimento delle finalità per i quali essi sono trattati ad eccezione di eventuali prolungamenti connessi ad attività di contratto o destinate a tenere informato l’utente sui servizi ed eventi organizzati dal titolare del trattamento.
                    <br /> In particolare la mail potrebbe essere conservata per un tempo più lungo in quanto inerente a successive proposte informative dell’attività del titolare.
                </p>
            </section>

            {/* Sezione 7 */}
            <section>
                <h2>7. Estremi identificativi del titolare, responsabile del trattamento dei dati</h2>
                <p>Per avere informazioni sul titolare del trattamento dei dati, contattare <a href="mailto:info@atmooss.com"> info@atmooss.com. </a></p>
            </section>

            {/* Sezione 8 */}
            <section>
                <h2>8. Diritti dell’interessato</h2>
                <h3>8.1 Art. 15 (diritto di accesso) e 16 (diritto di rettifica) del Reg. UE 2016/679</h3>
                <p>L'interessato ha il diritto di ottenere dal titolare del trattamento la conferma che sia o meno in corso un trattamento di dati personali che lo riguardano e in tal caso, di ottenere l'accesso ai dati personali e alle seguenti informazioni:</p>
                <ol type = "a">
                    <li>le finalità del trattamento;</li>
                    <li>le categorie di dati personali in questione;</li>
                    <li>i destinatari o le categorie di destinatari a cui i dati personali sono stati o saranno comunicati, in particolare se destinatari di paesi terzi o organizzazioni internazionali;</li>
                    <li>il periodo di conservazione dei dati personali previsto oppure, se non è possibile, i criteri utilizzati per determinare tale periodo;</li>
                    <li>l'esistenza del diritto dell'interessato di chiedere al titolare del trattamento la rettifica o la cancellazione dei dati personali o la limitazione del trattamento dei dati personali che lo riguardano o di opporsi al loro trattamento;</li>
                    <li>il diritto di proporre reclamo a un'autorità di controllo;</li>
                    <li>l'esistenza di un processo decisionale automatizzato, compresa la profilazione e, almeno in tali casi, informazioni significative sulla logica utilizzata, nonché l'importanza e le conseguenze previste di tale trattamento per l'interessato.</li>
                </ol>

                <h3>8.2 Diritto di cui all’art. 17 del Reg. UE 2016/679 - diritto alla cancellazione («diritto all'oblio»)</h3>
                <p>L'interessato ha il diritto di ottenere dal titolare del trattamento la cancellazione dei dati personali che lo riguardano senza ingiustificato ritardo e il titolare del trattamento ha l'obbligo di cancellare senza ingiustificato ritardo i dati personali, se sussiste uno dei motivi seguenti:</p>
                <ol type = "a">
                    <li>i dati personali non sono più necessari rispetto alle finalità per le quali sono stati raccolti o altrimenti trattati;</li>
                    <li>l'interessato revoca il consenso su cui si basa il trattamento conformemente all'articolo 6, paragrafo 1, lettera a), o all'articolo 9, paragrafo 2, lettera a), e se non sussiste altro fondamento giuridico per il trattamento;</li>
                    <li>l'interessato si oppone al trattamento ai sensi dell'articolo 21, paragrafo 1, e non sussiste alcun motivo legittimo prevalente per procedere al trattamento, oppure si oppone al trattamento ai sensi dell'articolo 21, paragrafo 2;</li>
                    <li>i dati personali sono stati trattati illecitamente;</li>
                    <li>i dati personali devono essere cancellati per adempiere un obbligo legale previsto dal diritto dell'Unione o dello Stato membro cui è soggetto il titolare del trattamento;</li>
                    <li>i dati personali sono stati raccolti relativamente all'offerta di servizi della società dell'informazione di cui all'articolo 8, paragrafo 1 del Reg. UE 2016/679.</li>
                </ol>

                <h3> 8.3 Diritto di cui all’ art. 18 Diritto di limitazione di trattamento</h3>
                <p> L'interessato ha il diritto di ottenere dal titolare del trattamento la limitazione del trattamento quando ricorre una delle seguenti ipotesi:</p>
                <ol type = "a">
                    <li>l'interessato contesta l'esattezza dei dati personali, per il periodo necessario al titolare del trattamento per verificare l'esattezza di tali dati personali;</li>
                    <li>il trattamento è illecito e l'interessato si oppone alla cancellazione dei dati personali e chiede invece che ne sia limitato l'utilizzo;</li>
                    <li>benché il titolare del trattamento non ne abbia più bisogno ai fini del trattamento, i dati personali sono necessari all'interessato per l'accertamento, l'esercizio o la difesa di un diritto in sede giudiziaria;</li>
                    <li>l'interessato si è opposto al trattamento ai sensi dell'articolo 21, paragrafo 1, Reg UE 2016/679 in attesa della verifica in merito all'eventuale prevalenza dei motivi legittimi del titolare del trattamento rispetto a quelli dell'interessato.</li>
                </ol>

                <h3>8.4 Diritto di cui all’art.20 Diritto alla portabilità dei dati</h3>
                <p>L'interessato ha il diritto di ricevere in un formato strutturato, di uso comune e leggibile da dispositivo automatico i dati personali che lo riguardano forniti a un titolare del trattamento e ha il diritto di trasmettere tali dati a un altro titolare del trattamento senza impedimenti da parte del titolare del trattamento.</p>
            </section>


            {/* Sezione 9 */}
            <section>
                <h2>9. Revoca del consenso al trattamento</h2>
                <p>Le è riconosciuta la facoltà di revocare il consenso al trattamento dei Suoi dati personali, inviando una mail al seguente indirizzo email: <a href="mailto:info@atmooss.com"> info@atmooss.com. </a> con il seguente testo: "revoca del consenso al trattamento di tutti i miei dati personali". Al termine di questa operazione i Suoi dati personali saranno rimossi dagli archivi nel più breve tempo possibile, fatte salve le limitazioni sopra indicate o gli obblighi di legge.
                <br />
                Se desidera avere maggiori informazioni sul trattamento dei Suoi dati personali, ovvero esercitare i diritti di cui può inviare una mail al seguente indirizzo email: <a href="mailto:info@atmooss.com"> info@atmooss.com. </a>. Prima di poterle fornire, o modificare qualsiasi informazione, potrebbe essere necessario verificare la Sua identità e rispondere ad alcune domande.
                </p>
            </section>
        </div>
    );
};

export default Privacy;
