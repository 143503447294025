import React, { useEffect, useState } from 'react';
import '../css/DailyAuth.css';

const DailyAuth = () => {
    const [eventData, setEventData] = useState(null);
    const [counter, setCounter] = useState(0); 
    const [isButtonDisabled, setIsButtonDisabled] = useState(false); 
    
    useEffect(() => {
        // Funzione per recuperare i dati dall'endpoint
        const fetchEventData = async () => {
            try {
                const response = await fetch('https://europe-west1-atmooss-dev.cloudfunctions.net/api/read-event-data'); 
                if (!response.ok) {
                    throw new Error('Errore nella richiesta dei dati dell\'evento');
                }
                const data = await response.json();
                setEventData(data.eventData.pastExtractions); 
                setCounter(data.counter); 

                const now = new Date();
                const eventHour = new Date();
                const [hours, minutes] = data.eventData.pastExtractions.hour.split(':');
                eventHour.setHours(hours);
                eventHour.setMinutes(minutes);

                if (now > eventHour) {
                    setIsButtonDisabled(true);
                }

                // Controllo se il pulsante deve essere disabilitato per click precedente
                const clickedData = localStorage.getItem('clicked');
                if (clickedData) {
                    const { clicked, timestamp } = JSON.parse(clickedData);
                    const clickedDate = new Date(timestamp);
                    // Se l'oggetto 'clicked' è true e non è scattata mezzanotte, disabilita il pulsante
                    if (clicked && clickedDate.toDateString() === now.toDateString()) {
                        setIsButtonDisabled(true);
                    } else {
                        // Se è un nuovo giorno, rimuovi il dato dallo storage
                        localStorage.removeItem('clicked');
                    }
                }
            } catch (error) {
                console.error('Errore:', error);
            }
        };

        fetchEventData();

        const intervalId = setInterval(() => {
            const now = new Date();
            if (now.getHours() === 0 && now.getMinutes() === 0) {
                localStorage.removeItem('clicked');
                localStorage.removeItem('userData');
            }
        }, 60000); 

        return () => clearInterval(intervalId); 
    }, []);

    // Funzione per gestire il click sul pulsante "PARTECIPERÒ"
    const handleParticipateClick = async () => {
        if (!isButtonDisabled) {
            try {
                const response = await fetch('https://europe-west1-atmooss-dev.cloudfunctions.net/api/increment-counter', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                });
    
                if (!response.ok) {
                    throw new Error('Errore durante l\'incremento del contatore');
                }
    
                const result = await response.json();
                setCounter(result.newCount); 

                let storedData = localStorage.getItem('userData');
                storedData = storedData ? JSON.parse(storedData) : {};
                const clickedData = {
                    clicked: true,
                    name: storedData.name,
                    surname: storedData.surname,
                    comune:storedData.comune,
                    phone: storedData.phone,
                    birthdate: storedData.birthdate, 
                    timestamp: new Date().toISOString(),
                };
                localStorage.setItem('clicked', JSON.stringify(clickedData));
                setIsButtonDisabled(true);
                
                
                const response2 = await fetch('https://europe-west1-atmooss-dev.cloudfunctions.net/api/update-csv-click', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        name: storedData.name,
                        surname: storedData.surname,
                        comune:storedData.comune,
                        phone: storedData.phone,
                        birthdate: storedData.birthdate, 
                        clicked: true
                    }),
                    });
                    if (!response2.ok) {
                        throw new Error('Errore durante l\'incremento del contatore');
                    }
                
            } catch (error) {
                console.error('Errore:', error);
            }}
    };
    
    if (!eventData) {
        return (
            <div className="loading-container">
                <div className="loading-spinner"></div>
                <p>Loading...</p>
            </div>
        );
    }
    const eventDate = new Date(eventData.data);
    eventDate.setDate(eventDate.getDate() + 1);

    const formattedDate = eventDate.toLocaleDateString('it-IT', {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric'
    });
    
    const capitalizedWeekday = formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
    const fontSize = counter.toString().length > 3 ? '1.3rem' : counter.toString().length > 2 ? '1.5rem' : '2rem';
    return (
        <div className="daily-auth">

            
            <div className="row row1">
                <div className="label">Data</div>
                <h1 className="header">{capitalizedWeekday}</h1>
            </div>
            <div className="row row2">
                <h2 className="place-name">{eventData.place}</h2>
                <p className="description">{eventData.description}</p>
                <p className="direction"> Mappa per raggiungere ATMOOSS:  
                    <a className = "direction-link" href={eventData.link} target="_blank" rel="noopener noreferrer">
                        <img src={require("../assets/images/map.png")} alt="Indicazioni" className="small-image" />
                    </a>
                </p>
                <div className="label address-label">Luogo</div>
            </div>
            <div className="row row3">
                <div className="label time-label">Orario</div>
                <h2 className="time">{eventData.hour}</h2>
            </div>
            <div className="row row4">
                <button 
                    className={`participate-button ${isButtonDisabled ? 'disabled' : ''}`} 
                    onClick={handleParticipateClick} 
                    disabled={isButtonDisabled}
                >
                    PARTECIPERÒ* 
                    {/* <br /> <p className='click-description'>Fai click per aggiugere la tua partecipazione</p> */}
                </button>
                {/* <p className="click-description2">*Per garantire un’esperienza positiva a tutti i partecipanti, 
fai click solo se hai la sicurezza di partecipare. </p> */}
                <div className="participants-container">
                    <div className="participants-estimate">
                        Numero di partecipanti
                    </div>
                    <div className="circle" style={{ fontSize }}>{counter}</div>
                </div> 
            </div>
            
        </div>
    );
}

export default DailyAuth;
