import React from 'react';
import '../css/Home.css'; 
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const navigate = useNavigate(); 

  const handleDailyClick = () => {
    navigate('/daily'); // Naviga verso la pagina Daily
  };
  return (
    <div className="home-container">
      <img src={require("../assets/images/logo1.png")} alt="ATMOOSS, pretend it's the 90s" className="centered-image" />
      
      {/* Aggiunta della frase centrata sotto l'immagine */}
      <div className="centered-text-container">
        <p className="centered-text">
          Conosci amici <strong>OFFLINE</strong>, <br />
          vivi esperienze <strong>AESTHETIC</strong>
        </p>
      </div>

      <div className="button-container">
        <button className="oval-button" onClick={handleDailyClick}>DAILY</button>
        <button className="oval-button">EXPERIENCES</button>
      </div>
    </div>
  );
};

export default Home;
