import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom'; 
import '../css/Daily.css'; 
import DailyAuth from './DailyAuth';
import Footer from './Footer';
import { isSafari } from 'react-device-detect';

const formatDateInput = (value) => {
  // Rimuoviamo i caratteri non numerici
  const cleanedValue = value.replace(/\D/g, '');
  
  // Aggiungiamo gli slash dove necessario
  let formattedValue = '';
  if (cleanedValue.length >= 2) {
    formattedValue += cleanedValue.slice(0, 2) + '/';
  } 
  if (cleanedValue.length >= 4) {
    formattedValue += cleanedValue.slice(2, 4) + '/';
    formattedValue += cleanedValue.slice(4, 8); // Aggiungiamo l'anno
  } else if (cleanedValue.length > 2) {
    formattedValue += cleanedValue.slice(2); // Solo giorno e mese
  }

  return formattedValue;
};

const Daily = () => {
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [comune, setComune] = useState('');
  const [phone, setPhone] = useState('');
  const [birthdate, setBirthdate] = useState(''); 
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [privacyAccepted, setPrivacyAccepted] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [validationMessages, setValidationMessages] = useState({});
  const [age, setAge] = useState(null); 
  const [placeholder, setPlaceholder] = useState('DATA DI NASCITA*');
  const [isPlaceholderVisibleNome, setIsPlaceholderVisibleNome] = useState(true);
  const [isPlaceholderVisibleCognome, setIsPlaceholderVisibleCognome] = useState(true);
  const [isPlaceholderVisibleComune, setIsPlaceholderVisibleComune] = useState(true);
  const [isPlaceholderVisibleCellulare, setIsPlaceholderVisibleCellulare] = useState(true);
  const [isLoading, setIsLoading] = useState(false); 

  const navigate = useNavigate();

  useEffect(() => {
    const storedData = localStorage.getItem('userData');
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      const currentTime = Date.now();
      if (parsedData.timestamp && new Date(parsedData.timestamp).toDateString() === new Date().toDateString()) {
        setShowDetails(true);
      } else {
        localStorage.removeItem('userData');
      }
    }
  }, []);

  const calculateAge = (date) => {
    const today = new Date();
    const birthDate = typeof date === 'string' ? new Date(date) : date;
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const validateInputs = () => {
    const phoneRegex = /^(?:\+?\d{1,3}[- ]?)?(3\d{8}|[3]\d{8}|\d{9,12})$/;

    let messages = {};

    if (name.trim().length < 3) {
      messages.name = 'Inserisci un nome valido.';
    }
    
    if (surname.trim().length < 3) {
      messages.surname = 'Inserisci un cognome valido.';
    }
    if (comune.trim().length < 2) {
      messages.comune = 'Inserisci un comune valido.';
    }

    if (!phoneRegex.test(phone.replace(/\s/g, ''))) {
      messages.phone = 'Inserisci un numero di cellulare valido.';
    }

    if (!birthdate) {
      messages.birthdate = 'Inserisci la data di nascita.';
    } else {
      // Regex per controllare il formato DD/MM/YYYY
      const dateRegex = /^(0?[1-9]|[12][0-9]|3[01])\/(0?[1-9]|1[0-2])\/(\d{4})$/;
  
      if (!dateRegex.test(birthdate)) {
        messages.birthdate = 'Inserisci una data valida, in formato DD/MM/YYYY.';
      } else {
        const [day, month, year] = birthdate.split('/').map(Number);
        const formattedDate = new Date(year, month - 1, day);
  
        // Controllo se la data è valida
        if (formattedDate.getFullYear() !== year || 
            formattedDate.getMonth() !== month - 1 || 
            formattedDate.getDate() !== day) {
          messages.birthdate = 'Inserisci una data valida.';
        } else {
          const calculatedAge = calculateAge(formattedDate);
          setAge(calculatedAge);
          if (calculatedAge < 18) {
            messages.birthdate = "Per partecipare ad ATMOOSS devi avere almeno 18 anni.";
            showAlertAndRedirect("Per partecipare ad ATMOOSS devi avere almeno 18 anni.");
          } else if (calculatedAge >35) {
            messages.birthdate = "Al momento, ATMOOSS è attivo solo per il range d'età 18-35 anni.";
            showAlertAndRedirect("Al momento, ATMOOSS è attivo solo per il range d'età 18-35 anni.");
          }
        }
      }
    }

    if (!termsAccepted) {
      messages.terms = 'Per proseguire, accetta i termini e le condizioni.';
    }

    if (!privacyAccepted) {
      messages.privacy = 'Per proseguire, accetta la privacy policy.';
    }

    setValidationMessages(messages);
    const hasErrors = Object.keys(messages).length > 0;
    return !hasErrors; 
    // return Object.keys(messages).length === 0; // Restituisce true se non ci sono messaggi
  };

  const handleDiscoverClick = async () => {
    setErrorMessage('');
    setIsSubmitted(true);
    const isValid = validateInputs();

    if (isValid) { 
      setIsLoading(true);
      try {
        const response = await fetch('https://europe-west1-atmooss-dev.cloudfunctions.net/api/update-csv', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            name,
            surname,
            comune,
            phone,
            birthdate, 
            termsAccepted, 
            privacyAccepted
          }),
        });

        if (response.ok) {
          const currentTimestamp = Date.now();
          localStorage.setItem('userData', JSON.stringify({ 
            logged: 'OK', 
            name: name,
            surname:surname,
            comune:comune,
            phone: phone,
            birthdate: birthdate, 
            timestamp: currentTimestamp }));
          setShowDetails(true);
        } else {
          showAlertAndRedirect("Errore durante l'invio dei dati.");
        }
      } catch (error) {
        console.error('Errore di rete:', error);
        showAlertAndRedirect("Errore di rete, riprova più tardi.");
      }
      finally {
        setIsLoading(false); 
      }
    }
  };
  const showAlertAndRedirect = (message) => {
    alert(message); 
    navigate('/'); 
  };
  const handleFocus = () => {
    setPlaceholder('DD/MM/YYYY');
  };

  const handleBlur = () => {
    if (!birthdate) {
      setPlaceholder('DATA DI NASCITA*');
    }
  };
  const handleBirthdayChange = (e) => {
    const value = e.target.value;

    // Consenti solo numeri e '/'
    if (/^[0-9/]*$/.test(value)) {
      let formattedValue = '';
      const numLength = value.length;

      if (numLength === 2) {
        formattedValue = value + '/'; // Aggiungi '/' dopo il giorno
      } else if (numLength === 5) {
        formattedValue = value + '/'; // Aggiungi '/' dopo il mese
      } else if (numLength > 10) {
        formattedValue = value.slice(0, 10); // Limita a 10 caratteri
      } else {
        formattedValue = value;
      }

      // Controllo per cancellare '/' se necessario
      if (value.length < birthdate.length && (value.endsWith('/') || birthdate.endsWith('/'))) {
        formattedValue = formattedValue.slice(0, -1); // Rimuovi l'ultimo carattere se si sta cancellando
      }

      setBirthdate(formattedValue);
    }
  };

  if (isLoading) {
    return (
      <div className="loading-container">
                <div className="loading-spinner"></div>
                <p>Loading...</p>
            </div>
    );
  }

  if (!showDetails) {
  return (
    <div className="daily-container">
      {/* <h1 className="daily-title">DAILY</h1> */}
      <img src={require("../assets/images/logo1.png")} alt="ATMOOSS, pretend it's the 90s" className="daily-title-image" />
      <h2 className="daily-subtitle">
        Ogni giorno un indirizzo e un orario, vai e conosci nuovi amici <strong>OFFLINE</strong>. 
        <br />
        Nulla di organizzato, tutto gratuito.
      </h2>
      
      <h2 className="daily-subtitle">
        
      SERVIZIO ATTIVO SOLO A MILANO!
      </h2>
      
      {/* {!showDetails ? ( */}
        <div className="input-container">
            <input
              type="text"
              placeholder={isPlaceholderVisibleNome ? "NOME*" : ""}
              value={name}
              onChange={(e) => setName(e.target.value)}
              onFocus={() => setIsPlaceholderVisibleNome(false)}
              onBlur={() => setIsPlaceholderVisibleNome(name.trim() === '')}
              required
              className={`oval-input ${isSubmitted && !name.trim() ? 'error' : ''}`}
            />
            {validationMessages.name && <div className="error-banner">{validationMessages.name}</div>}
            <input
              type="text"
              placeholder={isPlaceholderVisibleCognome ? "COGNOME*" : ""}
              value={surname}
              onChange={(e) => setSurname(e.target.value)}
              onFocus={() => setIsPlaceholderVisibleCognome(false)}
              onBlur={() => setIsPlaceholderVisibleCognome(surname.trim() === '')}
              required
              className={`oval-input ${isSubmitted && !surname.trim() ? 'error' : ''}`}
            />
            {validationMessages.surname && <div className="error-banner">{validationMessages.surname}</div>}
            
            <input
              type="text"
              value={birthdate}
              placeholder={placeholder}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onChange={handleBirthdayChange}
              maxLength={10} // Limita la lunghezza massima
              className={`oval-input ${isSubmitted && !birthdate ? 'error' : ''}`}
            />
           {/* <input
           type="text"
           placeholder="DATA DI NASCITA*"
           value={birthdate}
           minLength="10"
           maxLength="10"
           onFocus={handleDateFocus}
           onChange={handleBirthdateChange}
          //  onChange={(e) => setBirthdate(e.target.value)}
           className={`oval-input ${isSubmitted && !birthdate ? 'error' : ''}`}
           required
         /> */}
            
            {/* <input
                type={birthdate ? 'date' : 'text'}
                placeholder="DATA DI NASCITA*"
                value={birthdate}
                onChange={(e) => setBirthdate(e.target.value)}
                onFocus={handleDateFocus}
                onBlur={handleDateBlur}
                className={`oval-input ${isSubmitted && !birthdate ? 'error' : ''}`}
                required
              /> */}
              
              {validationMessages.birthdate && <div className="error-banner">{validationMessages.birthdate}</div>}
              <input
              type="comune"
              placeholder={isPlaceholderVisibleComune ? "COMUNE DI NASCITA*" : ""}
              value={comune}
              onChange={(e) => setComune(e.target.value)}
              onFocus={() => setIsPlaceholderVisibleComune(false)}
              onBlur={() => setIsPlaceholderVisibleComune(comune.trim() === '')}
              required
              className={`oval-input ${isSubmitted && !comune.trim() ? 'error' : ''}`}
            />
            {validationMessages.comune && <div className="error-banner">{validationMessages.comune}</div>}
            <input
              type="tel"
              placeholder={isPlaceholderVisibleCellulare ? " CELLULARE*" : ""}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              onFocus={() => setIsPlaceholderVisibleCellulare(false)}
              onBlur={() => setIsPlaceholderVisibleCellulare(phone.trim() === '')}
              required
              className={`oval-input ${isSubmitted && !/^(\+?\d{1,3}?)?\d{9,12}$/.test(phone) ? 'error' : ''}`}
            />
            {validationMessages.phone && <div className="error-banner">{validationMessages.phone}</div>}
            <div className="daily-note">
          <p>PRIMA DI PARTECIPARE DEVI SAPERE CHE:</p>
          <ul>
          <li> Essendo un servizio gratuito il numero di partecipanti potrebbe subire variazioni.</li>
          <li> Il servizio è attivo solo a Milano. Tutti i luoghi selezionati rispondono a criteri di sicurezza, centralità e vicinanza di mezzi pubblici al fine di garantire ai partecipanti, qualora non ci siano le condizioni ritenute necessarie, la facoltà di allontanarsi dall’incontro.</li>
          <li> Per la perfetta riuscita dell’incontro, ORARIO E LUOGO devono essere rispettati. </li>
          </ul>
          </div>
          
          <label className="checkbox-label">
            <input
              type="checkbox"
              checked={termsAccepted}
              onChange={() => {
                  setTermsAccepted(!termsAccepted);
              }}
              required
            />
            <span>
              Ho letto e accetto <Link to="/terms&conditions" target="_blank">termini e condizioni</Link>
            </span>
            {validationMessages.terms && <div className="error-banner">{validationMessages.terms}</div>}
          </label>

          <label className="checkbox-label">
            <input
              type="checkbox"
              checked={privacyAccepted}
              onChange={() => {
                  setPrivacyAccepted(!privacyAccepted);
              }}
              required
            />
            <span>
              Ho letto e accetto la <Link to="/privacy" target="_blank">privacy policy</Link>
            </span>
            {validationMessages.privacy && <div className="error-banner">{validationMessages.privacy}</div>}
          </label>

          {errorMessage && <div className="error-banner">{errorMessage}</div>}
          
          {/* Bottone per scoprire Daily */}
          <button className="oval-button-daily discover-button" onClick={handleDiscoverClick}>
            SCOPRI ATMOOSS DAILY
          </button>

          <Footer />
        </div>
        </div>
      )} 
      else {
        return(
        <div className='mobile-only'>
          <h1 className="daily-title">DAILY</h1>
            <h2 className="daily-subtitle">
            Qui trovi i dettagli per partecipare <br /> all’incontro ATMOOSS DAILY di oggi
      </h2>
          <DailyAuth />
          <p className="click-description2">*Per garantire un’esperienza positiva a tutti i partecipanti, <br />
fai click solo se hai la sicurezza di partecipare. </p>
          <Footer />
        </div>
      )}
}


    
//   );
// };

export default Daily;
